





























































import { Component, Watch } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import { deserialize } from "typescript-json-serializer"
import _ from "lodash"
import EventBus from "@/plugins/eventBus"
// eslint-disable-next-line
import { Route } from "vue-router"

/** Components */
import BackBar from "@/components/BackBar.vue"
import ProductColorGroupDetailComponent from "@/components/Products/ProductColorGroupDetailComponent.vue"
import CompareProductsBar from "@/components/Products/CompareProductsBar.vue"

/** Types | Api */
import ProductApi from "@/api/Product/ProductApi"
import InputMixin from "@/types/InputMixin"
import BreakpointMixin from "@/types/BreakpointMixin"
import TabMixin from "@/types/TabMixin"
import ProductCategoryDetail from "@/types/Product/ProductCategoryDetail"
import Product from "@/types/Product/Product"
import GroupColor from "@/types/Product/GroupColor"
import Color from "@/types/Product/Color"

@Component({
  name: "ProductItemDetail",
  components: {
    ProductColorGroupDetailComponent,
    BackBar,
    CompareProductsBar
  }
})
export default class ProductItemDetail extends mixins(BreakpointMixin, InputMixin, TabMixin) {
  private detail = new ProductCategoryDetail()

  private color = 1

  private product = new Product()

  // private colorGroup = 0; // color group for tab, idx of groupColors

  private colors: Color[] = []

  private groupColors: GroupColor[] = []

  private groupId = 0 // color group for mobile dropdown, actual groupId

  private categoryId = 0

  @Watch("groupId")
  onGroupIdChange() {
    this.fetchDetail().then(this.fetchColors).then(this.fetchProduct)
  }

  private get colorGroup(): number {
    return _.findIndex(this.groupColors, ["id", this.groupId])
  }

  @Watch("color")
  private onColorChange() {
    this.fetchProduct()
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange(newValue: Route, oldValue: Route) {
    // Router from vue-router
    if (newValue.name !== oldValue.name) this.fetchData()
    const lang = this.$i18n.locale
    this.$router.push({ name: "ProductGroupList", query: { lang } })
  }

  private back() {
    const lang = this.$i18n.locale
    this.$router.push({
      name: "ProductCategoryList",
      params: { id: `${this.$route.params.id}` },
      query: { lang }
    })
  }

  private emitChangeBanner() {
    EventBus.$emit("change-banner", this.detail.banner)
  }

  private fetchData() {
    this.fetchDetail()
      .then(this.fetchGroupColors)
      .then(this.fetchColors)
      .then(this.fetchProduct)
      .then(this.emitChangeBanner)
  }

  private mounted() {
    window.scrollTo({ top: 0 })
    this.groupId = parseInt(this.$route.params.id, 10)
    this.categoryId = parseInt(this.$route.params.detailId, 10)
    this.fetchData()
  }

  private fetchGroupColors() {
    ProductApi.listGroupColors(this.categoryId).then(({ data }) => {
      this.groupColors = _.map(data, (d) => deserialize<GroupColor>(d, GroupColor))
      this.groupId = _.first(this.groupColors)?.id || 1
    })
  }

  private shadeChange(shadeId: number) {
    this.color = shadeId
  }

  private fetchColors() {
    if (this.mobileScreen) {
      ProductApi.listColors(this.categoryId, this.groupId).then(({ data }) => {
        this.colors = _.map(data, (d) => deserialize<Color>(d, Color))
        this.color = this.colors[0]?.id || 1
      })
    } else {
      ProductApi.listColors(this.categoryId, this.groupId).then(({ data }) => {
        this.colors = _.map(data, (d) => deserialize<Color>(d, Color))
        this.color = this.colors[0]?.id || 1
      })
    }
  }

  private fetchProduct() {
    ProductApi.getProduct(this.categoryId).then(({ data }) => {
      this.product = deserialize<Product>(data, Product)
    })
  }

  private fetchDetail() {
    return ProductApi.getProductCategoryDetail(this.categoryId).then(({ data }) => {
      this.detail = deserialize<ProductCategoryDetail>(data, ProductCategoryDetail)
    })
  }

  private get productImageHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 368
    }
    if (bp.mdAndUp) {
      return 245
    }
    return 183
  }

  private get productImageWidth() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 626
    }
    if (bp.mdAndUp) {
      return 417
    }
    return 275
  }

  private changeTab(idx: number) {
    this.groupId = idx
  }

  getTabClass(idx: string) {
    const nidx = parseInt(idx, 10)
    let cls = ""
    if (this.colorGroup === nidx) {
      cls = cls.concat("custom-active-tab font-weight-semi-bold black--text")
    } else {
      cls = cls.concat("font-weight-light black--text")
    }
    return cls
  }
}
