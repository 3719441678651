import { JsonProperty, Serializable } from "typescript-json-serializer"
import DiamondFile from "@/types/DiamondFile"

@Serializable()
export default class Accessory {
  @JsonProperty("accessory_id") id!: number

  @JsonProperty() title!: string

  @JsonProperty() description!: string

  @JsonProperty({ type: DiamondFile }) file: DiamondFile = new DiamondFile()
}
