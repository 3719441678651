import { JsonProperty, Serializable } from "typescript-json-serializer"
// @ts-ignore
import * as urljoin from "url-join"
const baseFileUrl = process.env.VUE_APP_FILE_PATH

function makeFileUrl(filePath: string): string {
  return urljoin(baseFileUrl, filePath)
}
@Serializable()
export default class ProductCategoryDocs {
  @JsonProperty("product_category_doc_id")
  id?: number

  @JsonProperty()
  title?: string

  @JsonProperty({ name: "file_path", beforeDeserialize: makeFileUrl })
  link?: string
}
