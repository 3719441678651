



























import { Component, Prop, PropSync, Watch } from "vue-property-decorator"
import { mixins } from "vue-class-component"
import BreakpointMixin from "@/types/BreakpointMixin"

@Component({
  name: "YoutubeDialog"
})
export default class YoutubeDialog extends mixins(BreakpointMixin) {
  @PropSync("model", { default: false }) dialog!: boolean

  @Prop({ default: false }) persistent!: boolean

  @Prop() videoId!: string

  @Watch("dialog")
  onDialogChange() {
    // console.log('dialogChange', this.dialog);
    if (!this.dialog) {
      // @ts-ignore
      // eslint-disable-next-line no-unused-expressions
      this.$refs.youtube.player.stopVideo()
    }
  }
}
