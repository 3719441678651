import { JsonProperty, Serializable } from "typescript-json-serializer"
import DiamondFile from "@/types/DiamondFile"

@Serializable()
export default class Color {
  @JsonProperty("color_id") id!: number

  @JsonProperty("product_id") productId!: number

  @JsonProperty({ type: DiamondFile }) file: DiamondFile = new DiamondFile()

  @JsonProperty("title") title!: string

  hover = false
}
