var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mt-md-16 section-heading font-weight-light font-italic"},[_vm._v(" "+_vm._s(_vm.$t("product.detail.productColor"))+" ")])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('section-under-line')],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('div',{staticClass:"my-10 font-weight-light font-italic"},[_c('p',[_vm._v(_vm._s(_vm.$t("product.detail.hint")))])])])],1),(!_vm.mobileScreen)?_c('v-row',_vm._l((_vm.detail.products),function(product,index){return _c('v-col',{key:("item-" + (index + 1)),attrs:{"cols":"6","md":"3","sm":"4"}},[_c('card-product-action',_vm._b({},'card-product-action',{
          product: product,
          productCateId: _vm.$route.params.detailId,
          productCateName: _vm.detail.title,
          productGroupId: _vm.$route.params.id
        },false))],1)}),1):_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"wrapper-swiper"},[(_vm.detail.products.length > 0)?_c('swiper',{ref:"swiper",staticClass:"swiper",attrs:{"options":_vm.swiperOptions}},[_vm._l((_vm.groupProducts),function(productList,index){return _c('swiper-slide',{key:("product-list-" + index)},[_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},_vm._l((productList),function(product,index){return _c('v-col',{key:("product-" + (index + 1)),attrs:{"cols":"6"}},[_c('card-product-action',_vm._b({},'card-product-action',{
                    product: product,
                    productCateId: _vm.$route.params.detailId,
                    productCateName: _vm.detail.title,
                    productGroupId: _vm.$route.params.id
                  },false))],1)}),1)],1)}),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"})],2):_vm._e()],1)])],1),_c('v-row',{staticClass:"mb-16",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"mt-16 section-heading font-weight-light font-italic"},[_vm._v(" "+_vm._s(_vm.$t("product.detail.detail"))+" ")])]),_c('v-col',{staticClass:"pt-0 mb-6",attrs:{"cols":"12"}},[_c('section-under-line')],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticStyle:{"display":"flex","flex":"1","flex-direction":"column","/*justify-content":"center","*/ /*align-items":"center"},domProps:{"innerHTML":_vm._s(_vm.detail.productRef)}})])],1),(
      _vm.detail.vdo.length > 0 ||
      _vm.detail.docs.length > 0 ||
      (_vm.detail.link !== null && _vm.detail.link !== '') ||
      (Object.keys(_vm.detail.usageFile).length > 0 && _vm.detail.usageFile.fileUrl)
    )?_c('v-row',{staticClass:"mb-16 mb-md-6",attrs:{"justify":"center"}},[_vm._l((_vm.buttonTemplate),function(btn,idx){return [(
          !btn.action
            ? (_vm.detail && _vm.detail.usageType === 'URL' && _vm.detail.link) ||
              (_vm.detail && _vm.detail.usageType === 'FILE' && _vm.detail.usageFile && _vm.detail.usageFile.fileUrl)
            : _vm.detail[("" + (btn.value))] && _vm.detail[("" + (btn.value))].length > 0
            ? true
            : false
        )?_c('v-col',{key:("button" + idx),attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [(btn.action)?_c('v-btn',{class:_vm.mobileScreen ? 'border-radius-10' : 'border-radius-20',attrs:{"dark":"","depressed":"","block":"","color":hover ? 'primary lighten-1' : 'primary',"height":_vm.responsiveTextFieldHeight,"id":("download" + idx)},on:{"click":function($event){return _vm.actionExpand(btn.action)}}},[_c('span',{staticClass:"tab-font font-weight-semi-bold ml-auto pl-6"},[_vm._v(" "+_vm._s(_vm.$t(("product.detail.buttons." + (btn.value))))+" ")]),_c('span',{staticClass:"ml-auto"},[_c('v-icon',[_vm._v(" mdi-chevron-"+_vm._s(_vm.action === btn.action ? "up" : "down"))])],1)]):_c('v-btn',{class:_vm.mobileScreen ? 'border-radius-10' : 'border-radius-20',attrs:{"color":hover ? 'primary lighten-1' : 'primary',"dark":"","depressed":"","block":"","height":_vm.responsiveTextFieldHeight,"tag":"a","target":_vm.detail && _vm.detail.usageType === 'URL' && _vm.detail.link
                ? '_blank'
                : _vm.detail && _vm.detail.usageType === 'FILE' && _vm.detail.usageFile && _vm.detail.usageFile.fileUrl
                ? '_blank'
                : '',"href":_vm.detail && _vm.detail.usageType === 'URL'
                ? _vm.detail.link
                : _vm.detail && _vm.detail.usageType === 'FILE'
                ? _vm.detail.usageFile.fileUrl
                : '#',"id":("download" + idx)}},[_c('span',{staticClass:"tab-font font-weight-semi-bold"},[_vm._v(" "+_vm._s(_vm.$t(("product.detail.buttons." + (btn.value))))+" ")])])]}}],null,true)}),(btn.action === 'vdo')?_c('ListItemsExpand',{staticClass:"d-block d-md-none",attrs:{"isActive":_vm.action === 'vdo',"type":"vdo","items":_vm.detail.vdo}}):(btn.action === 'docs')?_c('ListItemsExpand',{staticClass:"d-block d-md-none",attrs:{"isActive":_vm.action === 'docs',"type":"docs","items":_vm.detail.docs}}):_vm._e()],1):_vm._e()]}),_c('v-col',{staticClass:"d-none d-md-block",attrs:{"cols":"12"}},[_c('ListItemsExpand',{attrs:{"isActive":_vm.action === 'vdo',"type":"vdo","items":_vm.detail.vdo}}),_c('ListItemsExpand',{attrs:{"isActive":_vm.action === 'docs',"type":"docs","items":_vm.detail.docs}})],1)],2):_vm._e(),_c('v-row',{attrs:{"justify":"start"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"section-heading font-weight-light font-italic"},[_vm._v(" "+_vm._s(_vm.$t("product.detail.accessories"))+" ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('section-under-line')],1)],1),_c('v-row',{staticClass:"mt-10",attrs:{"justify":"space-between"}},_vm._l((_vm.detail.accessories),function(acs,idx){return _c('v-col',{key:("accessories" + idx),staticClass:"mb-8",class:idx % 2 === 0 ? 'pr-4' : 'pl-4',attrs:{"cols":"12","md":"5"}},[_c('div',{staticClass:"d-flex flex-fill"},[_c('v-img',{staticClass:"mr-8",attrs:{"src":acs.file.fileUrl,"max-height":_vm.accessoriesImageSize,"max-width":_vm.accessoriesImageSize,"min-width":_vm.accessoriesImageSize,"aspect-ratio":"1"}}),_c('div',{staticClass:"text-wrap"},[_c('div',{staticClass:"text-title font-weight-semi-bold mb-4"},[_vm._v(" "+_vm._s(acs.title)+" ")]),_c('div',[_c('textarea',{ref:"textarea",refInFor:true,staticClass:"text-description",attrs:{"rows":"1","readonly":""},domProps:{"value":acs.description}})])])],1)])}),1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }