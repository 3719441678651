import { JsonProperty, Serializable } from "typescript-json-serializer"
import DiamondFile from "@/types/DiamondFile"

// @ts-ignore
import * as urljoin from "url-join"
const baseFileUrl = process.env.VUE_APP_FILE_PATH

function makeFileUrl(filePath: string): string {
  return urljoin(baseFileUrl, filePath)
}
@Serializable()
export default class ProductCategoryProduct {
  @JsonProperty("product_id")
  id?: number

  @JsonProperty("product_name")
  title?: string

  @JsonProperty({ name: "file_path", beforeDeserialize: makeFileUrl })
  image?: string
  
  @JsonProperty({ type: DiamondFile, name: "product_additional" })
  additionalFiles: Array<DiamondFile> = []
}
