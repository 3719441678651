



















































































































































































































import Component, { mixins } from "vue-class-component"
import { Prop, Watch } from "vue-property-decorator"
import _ from "lodash"
import { Swiper, SwiperSlide } from "vue-awesome-swiper"
import { SwiperOptions } from "swiper"

import ProductCategoryDetail from "@/types/Product/ProductCategoryDetail"
import Color from "@/types/Product/Color"
import BreakpointMixin from "../../types/BreakpointMixin"
import InputMixin from "../../types/InputMixin"

import SectionUnderLine from "@/components/Global/SectionUnderLine.vue"
import CardProductAction from "./CardProductAction.vue"
import ListItemsExpand from "./ListItemsExpand.vue"

@Component({
  name: "ProductColorGroupDetailComponent",
  components: {
    SectionUnderLine,
    CardProductAction,
    ListItemsExpand,
    Swiper,
    SwiperSlide
  }
})
export default class ProductColorGroupDetailComponent extends mixins(InputMixin, BreakpointMixin) {
  @Prop() detail!: ProductCategoryDetail

  @Prop({ default: [] }) colors!: Color[]

  @Prop() colorGroupId!: number

  private selectedShade = 0

  private selectedDetailImage = 0

  private action = ""

  $refs!: any

  /** Swiper Config */
  // // eslint-disable-next-line class-methods-use-this
  private swiperOptions: SwiperOptions = {
    slidesPerGroup: 1,
    slidesPerView: 3,
    loop: this.groupProducts.length > 1,
    loopFillGroupWithBlank: false,
    autoplay: this.groupProducts.length > 1,
    preloadImages: true,
    updateOnImagesReady: false,
    updateOnWindowResize: true,
    spaceBetween: 24,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      dynamicBullets: true,
      dynamicMainBullets: 1
    },
    // centeredSlides: true,
    breakpoints: {
      991: {
        slidesPerView: 3
      },
      100: {
        slidesPerView: 1,
        // slidesPerGroup: 3,
        centeredSlides: true,
        spaceBetween: 12
      }
    }
  }

  // private get buttonSection() {
  //   return _.reduce(
  //     _.map(this.buttonTemplate, "value"),
  //     // @ts-ignore
  //     (acc: boolean, v: string) => this.detail[v].fileUrl !== undefined || acc,
  //     true
  //   )
  // }

  private viewShade(idx: number) {
    this.selectedShade = idx
    this.$emit("shadeChange", this.colors[this.selectedShade].id)
  }

  // eslint-disable-next-line class-methods-use-this
  private download(idx: number) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const elm = document.getElementById(`download${idx}`)!
    elm.click()
  }

  actionExpand(action: string) {
    this.action === action ? (this.action = "") : (this.action = action)
  }

  resizeTextarea(e: any) {
    let area = e
    area.style.overflow = "hidden"
    area.style.height = area.scrollHeight + "px"
  }

  @Watch("detail", {
    immediate: true,
    deep: true
  })
  detailChange(newVal: unknown) {
    this.$nextTick(() => {
      if (this.$refs.textarea) {
        this.$refs.textarea.forEach((ta: any) => {
          this.resizeTextarea(ta)
        })
      }
    })
  }

  private viewImage(idx: number) {
    this.selectedDetailImage = idx
  }

  private get imageHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 368
    }
    if (bp.mdOnly) {
      return 246
    }
    return 275
  }

  private get imageWidth() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 598
    }
    if (bp.mdOnly) {
      return 400
    }
    return "100%"
  }

  private get accessoriesImageSize() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 134
    }
    if (bp.mdOnly) {
      return 106
    }
    return 106
  }

  private buttonTemplate = [
    {
      label: "ภาพการใช้งาน",
      value: "usage",
      href: "#"
    },
    {
      label: "วิดีโอ",
      value: "vdo",
      action: "vdo"
    },
    {
      label: "เอกสารที่เกี่ยวข้อง",
      value: "docs",
      action: "docs"
    }
  ]

  private get groupProducts() {
    const LIMIT_DESTINATION = 6
    return this.detail.products
      .map((item, index) => {
        if (index % LIMIT_DESTINATION === 0) {
          return this.detail.products.slice(index, index + LIMIT_DESTINATION)
        }
      })
      .filter((item) => {
        return item
      })
  }
}
